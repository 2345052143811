export const environment = {
  production: false,
  beBaseUrl: 'https://development.fia-intranet-backend.dev8.penceo.com',
  apiBaseUrl: 'https://development.fia-intranet-backend.dev8.penceo.com/api/v1',
  oktaBaseUrl: 'https://fia.okta-emea.com/oauth2/aus2mrv16lMBGdrQJ0i7',
  oktaClientId: '0oa2mhnlqhoI6rAcp0i7',
  oktaRedirectUri: 'https://development.fia-intranet.dev8.penceo.com/login',
  oktaTokenKey: 'fia_intanet_token',
  matomoTrackerUrl: '//matomo-tracking.fia.com/',
  matomoSiteId: '3'
};
