import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { forkJoin, map, Observable } from 'rxjs';
import { ListApiResponse } from '@models/list-api-response.model';
import { MenuModel } from '@models/menu.model';
import { LoginModel } from '@models/login.model';
import { UserWithTokenModel } from '@models/user-with-token.model';
import { ArticleModel } from '@models/article.model';
import { QuickLinkModel } from '@models/quicklink.model';
import { EventModel } from '@models/event.model';
import { NoticeRequestModel } from '@models/notice-request.model';
import { FileUploadModel } from '@models/file-upload.model';
import { NoticeModel } from '@models/notice.model';
import { NoticeCategoryModel } from '@models/notice-category.model';
import { PageModel } from '@models/page.model';
import { ContactFormModel } from '@models/contact-form.model';
import { SearchEntity } from '@models/enums/search-entity.enum';
import { DocCenterWidgetModel } from '@models/widgets/doc-center-widget.model';
import { NodeModel } from '@models/node.model';
import { FileManagerFileWrapperModel } from '@models/file-manager-file.model';
import { DocumentService } from '@services/document/document.service';
import { FolderDepartmentModel } from '@models/folder-department.model';
import { GalleryModel } from '@models/gallery.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private readonly apiBaseUrl = environment.apiBaseUrl;
  private readonly beBaseUrl = environment.beBaseUrl;

  constructor(
    private readonly http: HttpClient,
    private readonly documentService: DocumentService
  ) { }

  // AUTH

  public login(data: LoginModel): Observable<UserWithTokenModel> {
    return this.http.post<UserWithTokenModel>(`${this.apiBaseUrl}/login`, data, { withCredentials: true });
  }

  public loginOkta(idToken: string): Observable<UserWithTokenModel> {
    return this.http.post<UserWithTokenModel>(`${this.apiBaseUrl}/login`, { token: idToken }, { withCredentials: true, params: {'_format': 'json'} })
  }


  // NAVIGATION

  public getMainMenu(maxDepth: number = 3): Observable<MenuModel[]> {
    const params = new HttpParams()
      .set('maxDepth', maxDepth);

    return this.http.get<MenuModel[]>(`${this.apiBaseUrl}/menus/main`, { params });
  }

  public getQuickLinks(): Observable<QuickLinkModel[]> {
    return this.http.get<QuickLinkModel[]>(`${this.apiBaseUrl}/quicklinks`)
  }

  public getEmployeesMenu(maxDepth: number = 1): Observable<MenuModel[]> {
    const params = new HttpParams()
      .set('maxDepth', maxDepth);

    return this.http.get<MenuModel[]>(`${this.apiBaseUrl}/menus/employees`, { params });
  }


  // FILE UPLOAD

  public uploadFile(url: string, file: File): Observable<FileUploadModel> {
    const filename = encodeURIComponent(file.name);
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/octet-stream')
      .append('Content-Disposition', `file; filename="${filename}"`)
    return this.http.post<FileUploadModel>(url, new Blob([file]), { headers })
  }


  // ARTICLES

  public getArticles(paramsObject: any): Observable<ListApiResponse<ArticleModel>> {
    const params = new HttpParams({fromObject: paramsObject});

    return this.http.get<ListApiResponse<any>>(`${this.apiBaseUrl}/articles`, { params });
  }

  public getArticleCategories(): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/article-category`);
  }

  public getArticleTags(): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}/article-tag-category`);
  }

  // NODE

  public getNodeByAlias<T extends NodeModel>(alias: string): Observable<T> {
    const params = new HttpParams()
      .set('alias', alias);

    return this.http.get<T>(`${this.apiBaseUrl}/nodes`, { params });
  }

  // EVENTS

  public getEvents(paramsObject: any = {}): Observable<EventModel[]> {
    const params = new HttpParams({fromObject: paramsObject});

    return this.http.get<EventModel[]>(`${this.apiBaseUrl}/events`, { params });
  }

  // NOTICE BOARD

  public getNotices(paramsObject: any = {}): Observable<ListApiResponse<NoticeModel>> {
    const params = new HttpParams({fromObject: paramsObject});

    return this.http.get<ListApiResponse<NoticeModel>>(`${this.apiBaseUrl}/announcements`, { params });
  }

  public getNoticeCategories(paramsObject: any = {}): Observable<NoticeCategoryModel[]> {
    const params = new HttpParams({fromObject: paramsObject});

    return this.http.get<NoticeCategoryModel[]>(`${this.apiBaseUrl}/announcement-category`, { params });
  }

  public createNoticeRequest(data: NoticeRequestModel): Observable<{ status: string, info: string }> {
    return this.http.post<{ status: string, info: string }>(`${this.apiBaseUrl}/announcement`, data);
  }

  public uploadNoticeRequestFile(file: File): Observable<FileUploadModel> {
    const url = `${this.beBaseUrl}/file/upload/node/announcement/field_lead_image?_format=json`;
    return this.uploadFile(url, file);
  }

  public toggleNoticeStatus(id: string): Observable<any> {
    return this.http.patch<any>(`${this.apiBaseUrl}/announcement/${id}`, {});
  }

  // CONTACT

  public postContactForm(data: ContactFormModel): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(`${this.apiBaseUrl}/contact`, data);
  }

  // SEARCH

  public searchFor<T>(type: Exclude<SearchEntity, 'all'>, paramsObject: any = {}): Observable<ListApiResponse<T>> {
    const params = new HttpParams({fromObject: paramsObject});

    return this.http.get<ListApiResponse<T>>(`${this.apiBaseUrl}/search-${type}`, { params }).pipe(
      map((res: any) => {
        if (type === SearchEntity.document) {
          res.items = res.items?.map((item: FileManagerFileWrapperModel) => {
            item.file = this.documentService.createFileManagerFileFromWrapper(item);
            
            return item;
          })
        }
        
        return res;
      })
    );
  }

  public searchAll(contentParams: any = {}, documentParams: any = {}, peopleParams: any = {}): Observable<[content: any, document: any, people: any]> {
    return forkJoin([
      this.searchFor(SearchEntity.content, contentParams),
      this.searchFor(SearchEntity.document, documentParams),
      this.searchFor(SearchEntity.people, peopleParams),
    ])
  }

  // DOC CENTRE

  public getDocCenterWidget(): Observable<DocCenterWidgetModel> {
    return this.getNodeByAlias<PageModel>('/page/document-center').pipe(
      map(page => page.mainWidgets.items?.[0]?.widgets?.items?.[0] as DocCenterWidgetModel)
    );
  }

  public uploadDocCenterFile(file: File): Observable<FileUploadModel> {
    const url = `${this.beBaseUrl}/file/upload/node/document/field_file`;
    return this.uploadFile(url, file);
  }

  public createFolder(data: { name: string, parent: number, group?: string }): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.apiBaseUrl}/folder`, data);
  }

  public addFileToFolder(data: { name: string, file: number, folder: number, mainFolder: number }): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.apiBaseUrl}/document`, data);
  }

  public updateFolder(id: number, data: { name: string, group: string }): Observable<void> {
    return this.http.patch<void>(`${this.apiBaseUrl}/folder/${id}`, data);
  }

  public deleteFolder(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiBaseUrl}/folder/${id}`);
  }

  public deleteFile(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiBaseUrl}/document/${id}`);
  }

  public updateFile(id: number, data: { name: string, file?: number}): Observable<void> {
    return this.http.patch<void>(`${this.apiBaseUrl}/document/${id}`, data);
  }

  public getMyDepartments(): Observable<FolderDepartmentModel[]> {
    return this.http.get<FolderDepartmentModel[]>(`${this.apiBaseUrl}/my-groups`);
  }

  // Gallery

  public getGalleryList(paramsObject: any = {}): Observable<ListApiResponse<GalleryModel>> {
    const params = new HttpParams({fromObject: paramsObject});
    return this.http.get<ListApiResponse<GalleryModel>>(`${this.apiBaseUrl}/galleries`, { params });
  }
}

