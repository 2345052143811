import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const jwt = this.authService.getAccessToken();

    if (!jwt) {
      return next.handle(request);
    }

    const decoded: any = jwt_decode(jwt);

    if (decoded.exp * 1000 <= new Date().getTime()) {
      this.authService.logout();
      return next.handle(request);
    }

    const headers = request.headers.set('Authorization', `Bearer ${jwt}`);
    return next.handle(request.clone({headers}));
  }
}
