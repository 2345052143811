import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminRoles } from '@app/consts/admin-roles.conts';
import { environment } from '@env/environment';
import { LocalStorageKeyEnum } from '@models/enums/local-storage-key.enum';
import { LoginModel } from '@models/login.model';
import { UserWithTokenModel } from '@models/user-with-token.model';
import { UserModel } from '@models/user.model';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';
import { ApiService } from '@services/api/api.service';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router
  ) {
    const accessToken = this.getAccessToken();
    const user = this.getUserFromLocalStorage();

    if (accessToken && user) {
      this._user.next(user);
      this.setIsAdmin(user.roles);
    }
  }


  public login(data: LoginModel): Observable<void> {
    return this.apiService.login(data).pipe(
      map(this.processLogin.bind(this)) 
    )
  }

  public logout(): void {
    this._user.next(null);
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public getUser(): Observable<UserModel> {
    return this._user.asObservable();
  }

  public getUserValue(): UserModel {
    return this._user.value;
  }

  public isLoggedIn(): Observable<boolean> {
    return this._user.asObservable().pipe(
      map(user => !!user)
    );
  }

  public isLoggedInValue(): boolean {
    return !!this._user.value;
  }

  public isAdmin(): Observable<boolean> {
    return this._isAdmin.asObservable();
  }

  public isAdminValue(): boolean {
    return this._isAdmin.value;
  }

  public getAccessToken(): string {
    return localStorage.getItem(LocalStorageKeyEnum.ACCESS_TOKEN) as string;
  }

  private setAccessToken(token: string): void {
    localStorage.setItem(LocalStorageKeyEnum.ACCESS_TOKEN, token);
  }

  private setUser(user: UserModel): void {
    this.setUserToLocalStorage(user);
    this._user.next(user);
  }

  private setIsAdmin(roles: string[]|undefined): void {
    const hasAdminRole = AdminRoles.some(v => roles?.includes(v));
    this._isAdmin.next(hasAdminRole);
  }

  private setUserToLocalStorage(user: UserModel): void {
    localStorage.setItem(LocalStorageKeyEnum.USER, JSON.stringify(user));
  }

  private getUserFromLocalStorage(): UserModel {
    const user = localStorage.getItem(LocalStorageKeyEnum.USER);
    return user ? JSON.parse(user) : user;
  }

  public async processOktaToken(okta: OktaAuth) {
    try {
      const token = await okta.tokenManager.get(environment.oktaTokenKey);

      if (!token) {
        const tokenFromUrl = await okta.token.parseFromUrl();
        this.loginWithOktaToken(tokenFromUrl.tokens?.idToken);
      }
    } catch (err) {
      okta.token.getWithRedirect();
    }
  }

  private loginWithOktaToken(token: IDToken | undefined): void {
    if (!token) {
      return;
    }

    this.apiService.loginOkta(token?.idToken).subscribe(
      this.processLogin.bind(this)
    );
  }

  private processLogin(res: UserWithTokenModel): void {
    if (!res) {
      return;
    }

    this.setAccessToken(res.token as string);
    delete res?.token;

    this.setUser(res);
    this.setIsAdmin(res.roles);
    this.router.navigate(['/dashboard']);
  }

}
