import { Injectable } from '@angular/core';
import { FileManagerEntryTypeEnum } from '@models/enums/file-manager-entry-type.enum';
import { FileManagerFileModel, FileManagerFileWrapperModel } from '@models/file-manager-file.model';
import { FileModel } from '@models/file.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor() { }

  public createFileManagerFileFromWrapper(fileWrapper: FileManagerFileWrapperModel): FileManagerFileModel {
    return {
      ...fileWrapper.file,
      type: FileManagerEntryTypeEnum.file,
      title: fileWrapper.title,
      permissions: fileWrapper.permissions,
      folderTree: fileWrapper.folderTree,
      published: fileWrapper.published
    }
  }

  public createFileManagerFileFromFile(file: FileModel): FileManagerFileModel {
    return {
      ...file,
      id: file.fid,
      type: FileManagerEntryTypeEnum.file,
      title: file.filename,
      published: file.published,
      permissions: {create: false, update: false, delete: false},
    }
  }
}
