import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { environment } from '@env/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    NgxMatomoTrackerModule.forRoot({
      disabled: !environment.production,
      trackerUrl: environment.matomoTrackerUrl,
      siteId: environment.matomoSiteId,
      runOutsideAngularZone: true,
    }),
    NgxMatomoRouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
